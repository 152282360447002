import React, { useRef, useEffect, useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import gsap, { Power1 } from 'gsap';

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);


const Header = () => {

  const [nav, setNav] = useState(false);

  let header = useRef(null)

  useEffect(()=> {
    const headerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "body",
        start: "40px top",
        end: "999999px top",
        toggleActions: "play pause reverse reset",
        toggleClass: {
          targets: header,
          className: "scrolled"
        },
        //markers: true,
        //scrub: 0.25
      },
      ease: Power1.easeInOut
    });

    headerTimeline.to(header, { opacity: 1 })

  })
  
  return (
    <StaticQuery query={graphql`
      query MenuQuery {
        strapiHeader {
          title
          subtitle
          primary_navigation {
            id
            title
            subtitle
            link
          }
          secondary_navigation {
            id
            title
            # subtitle
            link
          }
        }
      }
    `} render={ data =>
      <header id="site-header" className={ nav ? "nav-active" : null } ref={el => header = el} >
        <div id="site-header-bg"/>

        <div id="site-header-content">

          <div id="site-titles">
            <h1 id="site-title"><Link to="/" >{ data.strapiHeader.title }</Link></h1>
          </div>

          <nav id="nav-container">
            <input type="checkbox" aria-label="toggle menu" checked={nav} onChange={() => setNav(!nav)} />
            <span className="hamburger-bar"></span>
            <span className="hamburger-bar"></span>
            <span className="hamburger-bar"></span>

            <ul id="nav">
              { data.strapiHeader.primary_navigation.map(item => <li key={item.id} className="primary"><Link to={item.link}>
                <div className="nav-item-title">{item.title}</div>
                {item.subtitle && <div className="nav-item-subtitle">{item.subtitle}</div> }
              </Link></li>) }
              { data.strapiHeader.secondary_navigation.map(item => <li key={item.id} className="secondary"><Link to={item.link}>
                <div className="nav-item-title">{item.title}</div>
                {item.subtitle && <div className="nav-item-subtitle">{item.subtitle}</div> }
              </Link></li>) }
            </ul>
          </nav>

        </div>
      </header>
    } />
  )
}

export default Header

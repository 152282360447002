import React from "react"

const Footer = () => (
  <footer>
    {`built by `}
    <a href="https://waag.org">waag futurelab</a>
  </footer>
)

export default Footer
